.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.noteTooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.noteTooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Schriftgröße des Textes im Tooltip */
  font-size: 14px;

  /* Positionierung des Tooltips */
  position: absolute;
  left: -340px; /* Position 340px links vom .noteTooltip-Element */
  top: 50%; /* Zentriere den Tooltip vertikal */
  transform: translateY(-50%); /* Korrigiere die vertikale Positionierung */
  z-index: 1;
}

.noteTooltip:hover .tooltiptext {
  visibility: visible;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
